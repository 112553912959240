export const {
  VITE_APP_ENV,
  VITE_GOOGLE_PAY_ENV,
  VITE_GOOGLE_APPLE_PAY_CHECKOUT_URL,
  VITE_APPLE_PAY_MERCHANT_VALIDATOR_ENDPOINT,
  VITE_APPLE_PAY_AVAILABLE,
  VITE_MONEYHASH_OUTLET_IDS,
  VITE_INITIAL_CDN_PATH,
  VITE_WEBHOOK_BASE_URL,
  VITE_PAYMENTS_API_BASE_URL,
  // ts-prune-ignore-next
  VITE_SENTRY_DSN,
} = import.meta.env;
export const currency = 'AED';
export const countryCode = 'AE';
