import { Bill } from '@/components/Bill/Bill';
import BrandPresentation from '@/components/BrandPresentation/BrandPresentation';
import CheckoutPayment from '@/components/CheckoutPayment/CheckoutPayment';
import { MoneyHashPayments } from '@/components/PaymentMoneyHashSdk/MoneyHashPayments';
import Summary from '@/components/Summary/Summary';
import Tips from '@/components/Tips/Tips';
import {
  VITE_MONEYHASH_OUTLET_IDS,
  VITE_WEBHOOK_BASE_URL,
  countryCode,
  currency,
} from '@/constants/constants';
import { ErrorsProvider } from '@/context/Errors/ErrorsContext';
import { PaymentProvider } from '@/context/Payment/PaymentContext';
import { useBill } from '@/hooks/useBill';
import { useBrand } from '@/hooks/useBrand';
import { usePayment } from '@/hooks/usePayment';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Checkout.module.scss';

const OUTLET_IDS_WITH_MONEYHASH = VITE_MONEYHASH_OUTLET_IDS?.split(',') || [];

const CheckoutContent = () => {
  const { isValid, queryParams } = useValidateQueryParams();
  const { paymentState, selectedTipAmount } = usePayment();
  const { outletId, tableNumber } = queryParams();
  const paymentSystem = new URLSearchParams(window.location.search).get('paymentSystem');
  const { data: brandData } = useBrand();
  const { data: bill, isError } = useBill();
  const navigate = useNavigate();
  const paymentComponentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = paymentComponentRef.current;
    if (!element) return;

    const handleResize = () => {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, []);

  const brandName = brandData?.name || 'Kitopay';
  const baseUrl = window.location.origin;

  const successUrl = useMemo(() => {
    if (bill) {
      return `/checkout/success/?order=${bill.orderId}&brand=${brandName}&cashback=${bill.estimatedCashback}&outletId=${outletId}&tableNumber=${tableNumber}`;
    }
    return `/checkout/success`;
  }, [bill, tableNumber, outletId, brandName]);

  const failedUrl = `/checkout/failed`;

  const onPaymentResult = useCallback(
    (result: { success: boolean; message?: string }) => {
      if (result?.success) {
        navigate(successUrl);
      } else {
        navigate(`${failedUrl}?message=${result.message ? result.message : ''}`, { replace: true });
      }
    },
    [navigate, successUrl]
  );

  if (!isValid())
    return (
      <div>
        Error loading table information.
        <br />
        Please scan QR code again
      </div>
    );

  const isMoneyHashPaymentSystemFF = paymentSystem === 'moneyhash';

  const isMoneyHashPayments =
    outletId &&
    isError === false &&
    bill &&
    brandData &&
    paymentState.total > 0 &&
    (OUTLET_IDS_WITH_MONEYHASH.includes(outletId) || isMoneyHashPaymentSystemFF);

  const baseWebHookUrl = VITE_WEBHOOK_BASE_URL;

  return (
    <div className={styles.root}>
      <div className={styles.orderInfo}>
        <div>
          <BrandPresentation />
          <Bill />
        </div>
        <Tips />
        <Summary haveToShowCashbackBanner />
      </div>

      <div ref={paymentComponentRef}>
        {isMoneyHashPayments ? (
          <div className={styles.paymentContainer}>
            <h1>Payments</h1>
            <MoneyHashPayments
              amount={bill.total}
              tipAmount={selectedTipAmount}
              currency={currency}
              countryCode={countryCode}
              successRedirectUrl={`${baseUrl}${successUrl}`}
              failedRedirectUrl={`${baseUrl}${failedUrl}`}
              onResult={onPaymentResult}
              webhookUrl={`${baseWebHookUrl}/api/dine-in-public/payments-api/bills/${bill.billId}/payments`}
            />
          </div>
        ) : (
          <ErrorsProvider>
            <CheckoutPayment />
          </ErrorsProvider>
        )}
      </div>
    </div>
  );
};

const Checkout = () => (
  <PaymentProvider>
    <CheckoutContent />
  </PaymentProvider>
);

export default Checkout;
