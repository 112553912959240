import appleIcon from '@/assets/images/apple.svg';
import MoneyHashHeadless, {
  Elements,
  IntentStateDetails,
  Method,
  PaymentMethodSlugs,
} from '@moneyhash/js-sdk/headless';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { PaymentMethods } from './PaymentMethods/PaymentMethods';
import styles from './PaymentProcessing.module.scss';
import { usePaymentHandlers } from './usePaymentHandlers';

type OnResultParams = {
  success: boolean;
  message?: string;
  validationErrors?: Record<string, string>;
};

interface PaymentsProps {
  webhookUrl?: string;
  amount: number;
  accentColor?: string;
  currency: string;
  countryCode: string;
  onResult: (res: OnResultParams) => void;
  moneyHashInstance: MoneyHashHeadless<'payment'>;
  moneyHashElements: Elements;
  intentId: string;
}

const GOOGLE_PAY_BTN_ID = 'moneyHash-google-pay-button';
const APPLE_PAY_BTN_ID = 'moneyHash-apple-pay-button';
const CARD_PAY_BTN_ID = 'moneyHash-card-pay-button';

type GoogleButtonOptions = {
  buttonColor?: google.payments.api.ButtonColor;
  buttonLocale?: string;
  buttonRadius?: number;
  buttonSizeMode?: google.payments.api.ButtonSizeMode;
  buttonType?: google.payments.api.ButtonType;
};

const GooglePayButtonStyles: GoogleButtonOptions = {
  buttonColor: 'black',
  buttonType: 'short',
  buttonSizeMode: 'fill',
  buttonRadius: 20,
};

const FF_REDIRECT_TO_FAILED_PAGE = false;

export const PaymentProcessing = (props: PaymentsProps) => {
  const [methods, setMethods] = useState<Method[]>([]);
  const [isBillingDataValid, setIsBillingDataValid] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isMethodsLoading, setIsMethodsLoading] = useState<boolean>(true);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [billingData, setBillingData] = useState<Record<string, string>>({});
  const [formFields, setFormFields] = useState<
    IntentStateDetails<'FORM_FIELDS'>['formFields'] | null
  >(null);
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethodSlugs | null>(null);
  const {
    amount,
    currency,
    countryCode,
    onResult,
    accentColor,
    moneyHashInstance,
    moneyHashElements,
    intentId,
  } = props;

  const resultHandler = ({ success, message, validationErrors }: OnResultParams) => {
    setIsProcessing(false);
    if (success) {
      onResult({ success: true });
    } else {
      if (validationErrors) {
        setValidationErrors(validationErrors);
      }
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('Payment failed');
      }

      if (FF_REDIRECT_TO_FAILED_PAGE) {
        if (!message && (!validationErrors || Object.keys(validationErrors).length === 0)) {
          onResult({ success: false, message: 'unknown issue' });
        }
      }
    }
  };

  const { payWithApplePay, payWithCard, payWithGooglePay } = usePaymentHandlers({
    moneyHashInstance,
    intentId,
    currency,
    countryCode,
    amount,
    onResult: resultHandler,
  });

  moneyHashElements.on('validityChange', isValid => {
    setIsBillingDataValid(isValid);
  });

  const onSetBillingField = (name: string, value: string) => {
    setBillingData({
      ...billingData,
      [name]: value,
    });
  };

  // getIntentMethods
  useEffect(() => {
    setIsMethodsLoading(true);
    moneyHashInstance
      .getIntentMethods(intentId)
      .then(({ paymentMethods, expressMethods }) => {
        setMethods([...expressMethods, ...paymentMethods]);
        setIsMethodsLoading(false);
      })
      .catch(err => {
        setIsMethodsLoading(false);
        Sentry.captureMessage(`Failed to get payment methods ${JSON.stringify(err)}`);
        onResult({ success: false, message: 'Failed to get payment methods' });
      });
  }, [intentId, onResult, moneyHashInstance.getIntentMethods]);

  // effect on change selected pay method
  useEffect(() => {
    if (selectedMethod === 'GOOGLE_PAY') {
      moneyHashInstance.renderGooglePayButton({
        ...GooglePayButtonStyles,
        onClick: () => {
          setIsProcessing(true);
          payWithGooglePay();
        },
      });
    }
  }, [selectedMethod, moneyHashInstance.renderGooglePayButton, payWithGooglePay]);

  const onSelectPaymentMethod = (method: PaymentMethodSlugs) => {
    setErrorMessage('');
    if (selectedMethod !== 'CARD' && method === 'CARD') {
      setFormFields(null);
    }

    setSelectedMethod(method);
    // for some reason APPLE_PAY method does not require to proceedWith
    if (method !== 'APPLE_PAY') {
      moneyHashInstance
        .proceedWith({
          intentId,
          type: 'method',
          id: method,
        })
        .then(res => {
          const { stateDetails } = res;

          if (
            stateDetails &&
            'formFields' in stateDetails &&
            stateDetails.formFields &&
            method === 'CARD' &&
            stateDetails.formFields.card
          ) {
            setFormFields(stateDetails.formFields);
            setAccessToken(stateDetails.formFields.card.accessToken);
          }
        });
    }
  };

  return (
    <div>
      {isMethodsLoading ? (
        <p>Loading payment methods...</p>
      ) : (
        <>
          <PaymentMethods
            accentColor={accentColor}
            elements={moneyHashElements}
            paymentMethods={methods}
            intentId={intentId}
            onSelect={onSelectPaymentMethod}
            formFields={formFields}
            onSetBillingField={onSetBillingField}
            validationErrors={validationErrors}
            isProcessing={isProcessing}
          />

          {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

          {isProcessing && <div className={styles.shimmer}></div>}

          {selectedMethod === 'GOOGLE_PAY' && (
            <div id={GOOGLE_PAY_BTN_ID} className={styles.hiddenWhenEmpty}></div>
          )}

          {selectedMethod === 'APPLE_PAY' && (
            <div id={APPLE_PAY_BTN_ID} className={styles.hiddenWhenEmpty}>
              {selectedMethod === 'APPLE_PAY' && (
                <button
                  className={`${styles.payButton} ${styles.applePayButton}`}
                  disabled={isProcessing}
                  onClick={() => {
                    setIsProcessing(true);
                    payWithApplePay();
                  }}
                >
                  <img src={appleIcon} alt="Apple Pay" className={styles.icon} />
                  Pay
                </button>
              )}
            </div>
          )}

          {selectedMethod === 'CARD' && (
            <div id={CARD_PAY_BTN_ID} className={styles.payButtonContainer}>
              {selectedMethod === 'CARD' && accessToken && (
                <button
                  disabled={!isBillingDataValid || isProcessing}
                  className={styles.payButton}
                  onClick={() => {
                    setIsProcessing(true);
                    if (isBillingDataValid) {
                      payWithCard(accessToken, billingData);
                    } else {
                      alert('Please fill all required fields');
                    }
                  }}
                >
                  Pay
                </button>
              )}
            </div>
          )}

          <div id="rendered-url-iframe-container" className={styles.iframeContainer}></div>
        </>
      )}
    </div>
  );
};
