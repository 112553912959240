import billUnpaid from '@/assets/images/billUnpaid.png';
import { useEffect } from 'react';
import styles from './PaymentFailure.module.scss';

const PaymentFailure = () => {
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'purchase_completed',
        success_status: '/checkout/failed',
      });
    }
  }, []);

  const message = new URLSearchParams(window.location.search).get('message') || '';

  return (
    <div className={styles.root}>
      <div className={styles.imgWrapper}>
        <img src={billUnpaid} alt="billUnpaid" />
      </div>
      <div className={styles.header}>
        <h1>Payment Failed</h1>
        <h2>Sorry, your payment was unsuccessful</h2>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default PaymentFailure;
