import { VITE_PAYMENTS_API_BASE_URL } from '@/constants/constants';
import * as Sentry from '@sentry/react';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const paymentsApiUrl = VITE_PAYMENTS_API_BASE_URL;

interface CreateIntentParams {
  amount: number;
  extraCharges: {
    tipAmount: number;
  };
  currency: string;
  webhookUrl: string;
  successful_redirect_url: string;
  failed_redirect_url: string;
  threeds: boolean | null;
}

interface IntentResponse {
  paymentId: string;
}

const createIntent = (
  signal: AbortSignal,
  {
    amount,
    currency,
    extraCharges,
    webhookUrl,
    successful_redirect_url,
    failed_redirect_url,
    threeds,
  }: CreateIntentParams
) =>
  fetch(`${paymentsApiUrl}/payments-public/payments/intents`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      amount,
      currency,
      webhookUrl,
      extraCharges,
      successful_redirect_url,
      failed_redirect_url,
      threeds,
      customFields: {},
    }),
    signal,
  }).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  });

export const useCreateIntent = (): UseMutationResult<IntentResponse, Error, CreateIntentParams> & {
  cancel: () => void;
} => {
  const controller = new AbortController();

  const mutation = useMutation({
    mutationFn: (params: CreateIntentParams) => {
      const signal = controller.signal;
      return createIntent(signal, params);
    },
    onError: (err: Error) => {
      if (err.name === 'AbortError') {
        Sentry.captureException('Mutation aborted');
      } else {
        Sentry.captureException(err);
      }
    },
  });

  return {
    ...mutation,
    cancel: () => controller.abort(),
  };
};
