import { FieldType } from '@moneyhash/js-sdk/headless';
import { useCallback, useState } from 'react';
import { inputStyle } from '../CardFields/config';
import styles from './BillingInput.module.scss';

interface Props {
  name: string;
  placeholder: string;
  disabled: boolean;
  type: FieldType;
  onSetBillingField: (name: string, value: string) => void;
  errorMessage?: string;
}

export const BillingInput = ({
  name,
  type,
  placeholder,
  errorMessage,
  disabled,
  onSetBillingField,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const className = `${styles.inputHelperText} ${errorMessage || error ? styles.error : ''}`;

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.value.trim() === '') {
        setError('This field is required');
        onSetBillingField(name, '');
      } else {
        setError('');
        onSetBillingField(name, e.target.value.trim());
      }
    },
    [onSetBillingField, name]
  );

  return (
    <div key={name}>
      <div id={name} className={styles.inputWrapper} style={{ border: styles.borderStyle }}>
        <input
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          className={styles.billingInput}
          type={type}
          placeholder={placeholder}
          style={inputStyle}
        />
      </div>
      <p className={className}>{errorMessage || error}</p>
    </div>
  );
};
