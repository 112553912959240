import {
  Elements,
  IntentStateDetails,
  Method,
  PaymentMethodSlugs,
} from '@moneyhash/js-sdk/headless';
import { useMemo, useState } from 'react';
import { BillingInput } from '../BillingFields/BillingInput';
import { CardFields } from '../CardFields/CardFields';
import styles from './PaymentMethods.module.scss';

export interface Props {
  elements: Elements;
  intentId: string;
  isProcessing: boolean;
  accentColor?: string;
  paymentMethods: Method[];
  onSelect: (id: PaymentMethodSlugs) => void;
  formFields: IntentStateDetails<'FORM_FIELDS'>['formFields'] | null;
  onSetBillingField: (name: string, value: string) => void;
  validationErrors?: Record<string, string>;
}

const isAdditionalFieldsRequired = true;

export const PaymentMethods = ({
  formFields,
  paymentMethods,
  onSelect,
  elements,
  accentColor,
  onSetBillingField,
  validationErrors,
  isProcessing,
}: Props) => {
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);

  const requiredBillingFields = useMemo(() => {
    return formFields?.billing?.filter(field => field.validation.required) || [];
  }, [formFields?.billing]);

  return (
    <div>
      {paymentMethods.length === 0 && <p>No payment methods available</p>}

      {paymentMethods.map(({ id, title, icons }) => (
        <div key={id} className={styles.paymentMethodRow}>
          <label>
            <div className={styles.icon}>
              {icons[0] ? <img src={icons[0]} alt={title} /> : '🪙'}
            </div>
            <h2>{title}</h2>
            <input
              disabled={isProcessing}
              type="radio"
              onChange={() => {
                onSelect(id);
                setSelectedMethod(id);
              }}
              className={styles.radioInput}
              style={
                {
                  '--radio-input-color': accentColor,
                } as React.CSSProperties
              }
              name="payment-method"
              value={id}
            />
          </label>
          {selectedMethod === id && id === 'CARD' && (
            <CardFields elements={elements}>
              {isAdditionalFieldsRequired && (
                <>
                  {requiredBillingFields.map(field => (
                    <BillingInput
                      key={field.name}
                      disabled={isProcessing}
                      type={field.type}
                      onSetBillingField={onSetBillingField}
                      name={field.name}
                      placeholder={field.label}
                      errorMessage={validationErrors?.[field.name]}
                    />
                  ))}
                </>
              )}
            </CardFields>
          )}
        </div>
      ))}
    </div>
  );
};
