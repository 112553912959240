import { Element, ElementType, Elements } from '@moneyhash/js-sdk/headless';
import { useEffect, useRef, useState } from 'react';
import styles from './Input.module.scss';
import { borderStyle, inputStyle } from './config';

interface Props {
  elements: Elements;
  name: ElementType;
  placeholder: string;
  error?: string;
}

export interface OnErrorParams {
  isValid: boolean;
  error: string | null;
}

export const ElementsInput = ({ name, placeholder, elements, error }: Props) => {
  const inputElement = useRef<Element | null>(null);
  const [errorMessages, setErrorMessages] = useState<string>('');

  useEffect(() => {
    if (inputElement.current) {
      return;
    }
    inputElement.current = elements.create({
      elementType: name,
      elementOptions: {
        selector: `#${name}`,
        placeholder,
        styles: inputStyle,
      },
    });

    inputElement.current.mount();

    inputElement.current.on('error', ({ isValid, error }: OnErrorParams) => {
      setErrorMessages(isValid ? '' : error || 'Unknown error');
    });
  }, [elements.create, name, placeholder]);

  const className = `${styles.inputHelperText} ${errorMessages || error ? styles.error : ''}`;

  return (
    <div>
      <div id={name} className={styles.inputWrapper} style={{ border: borderStyle }}></div>
      <p className={className}>{errorMessages || error}</p>
    </div>
  );
};
