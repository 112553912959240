import { VITE_APP_ENV } from '@/constants/constants';
import {
  Elements,
  default as MoneyHash,
  default as MoneyHashHeadless,
} from '@moneyhash/js-sdk/headless';
import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { PaymentProcessing } from './PaymentProcessing';

interface Props {
  webhookUrl?: string;
  successRedirectUrl: string;
  failedRedirectUrl: string;
  amount: number;
  accentColor?: string;
  currency: string;
  countryCode: string;
  onResult: (res: { success: boolean; message?: string }) => void;
  intentId: string;
}

const fontSourceCss = 'https://fonts.googleapis.com/css2?family=Lexend:wght@300';

export const MoneyHashInitializer = (props: Props) => {
  const moneyHashInstanceRef = useRef<MoneyHashHeadless<'payment'> | null>(null);
  const moneyHashElementsRef = useRef<Elements | null>(null);
  const [error, setError] = useState<string>('');
  const [isInitiating, setIsInitiating] = useState<boolean>(true);
  const {
    amount,
    currency,
    countryCode,
    onResult,
    successRedirectUrl,
    failedRedirectUrl,
    accentColor,
    intentId,
  } = props;

  useEffect(() => {
    if (successRedirectUrl && !successRedirectUrl.startsWith('https://')) {
      Sentry.captureMessage('successRedirectUrl should start with https://');
      setError('successRedirectUrl should start with "https://"');
    }
  }, [successRedirectUrl]);

  useEffect(() => {
    if (failedRedirectUrl && !failedRedirectUrl.startsWith('https://')) {
      Sentry.captureMessage('failedRedirectUrl should start with https://');
      setError('failedRedirectUrl should start with "https://"');
    }
  }, [failedRedirectUrl]);

  // Initialize moneyHash
  useEffect(() => {
    // Initialize the library only once
    if (moneyHashInstanceRef.current === null) {
      moneyHashInstanceRef.current = new MoneyHash<'payment'>({
        type: 'payment',
        googlePay: {
          environment: VITE_APP_ENV !== 'prod' ? 'TEST' : 'PRODUCTION',
        },
        onComplete: () => {
          onResult({ success: true });
        },
        onFail: ({ intent, transaction }) => {
          Sentry.captureMessage(JSON.stringify({ intent, transaction }));
          onResult({ success: false, message: 'Payment failed' });
        },
      });
      moneyHashElementsRef.current = moneyHashInstanceRef.current.elements({ fontSourceCss });
      setIsInitiating(false);
    }
  }, [onResult]);

  if (error) {
    return <p color="red">{error}</p>;
  }

  return (
    <>
      {!isInitiating && moneyHashInstanceRef.current && moneyHashElementsRef.current ? (
        <PaymentProcessing
          amount={amount}
          currency={currency}
          countryCode={countryCode}
          onResult={onResult}
          accentColor={accentColor}
          moneyHashInstance={moneyHashInstanceRef.current}
          moneyHashElements={moneyHashElementsRef.current}
          intentId={intentId}
        />
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};
