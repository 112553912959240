import kitoCoin from '@/assets/images/KitoCoin.svg';
import CashbackEstimation from '@/components/CashbackEstimation/CashbackEstimation';
import { DEFERRED_BASE_URL } from '@/constants/config';
import { useLocation } from 'react-router-dom';
import styles from './SuccessTitle.module.scss';

export const SuccessTitle = () => {
  const query = new URLSearchParams(useLocation().search);
  const orderId = query.get('order') || '';
  const cashbackEstimate = query.get('cashback');
  const deferredLink = `${DEFERRED_BASE_URL}${orderId}`;

  return (
    <div className={styles.root}>
      <div>
        <h2 className={styles.title2}>Payment successful</h2>
        <img src={kitoCoin} alt="Kitopi" />
        <h1 className={styles.title1}>Your reward</h1>

        <CashbackEstimation cashbackEstimate={cashbackEstimate} />
      </div>

      <button className={styles.button} onClick={() => window.open(deferredLink, '_blank')}>
        Download app
      </button>
    </div>
  );
};
