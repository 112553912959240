import { Elements } from '@moneyhash/js-sdk/headless';
import { useEffect, useRef, useState } from 'react';
import type { OnErrorParams } from './ElementsInput';
import styles from './Input.module.scss';
import { borderStyle, inputStyle } from './config';

export const ElementsExpire = ({ elements }: { elements: Elements }) => {
  const [errorMonthMessages, setErrorMonthMessages] = useState<string>('');
  const [errorYearMessage, setErrorYearMessages] = useState<string>('');

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const expiryMonthEl = useRef<any>(null);
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const expiryYearEl = useRef<any>(null);

  useEffect(() => {
    if (expiryMonthEl.current || expiryYearEl.current) {
      return;
    }

    expiryMonthEl.current = elements.create({
      elementType: 'cardExpiryMonth',
      elementOptions: {
        selector: `#cardExpiryMonth`,
        placeholder: 'Expiry MM',
        styles: inputStyle,
      },
    });

    expiryYearEl.current = elements.create({
      elementType: 'cardExpiryYear',
      elementOptions: {
        selector: `#cardExpiryYear`,
        placeholder: 'YY',
        styles: inputStyle,
      },
    });

    expiryMonthEl.current.mount();
    expiryYearEl.current.mount();

    expiryMonthEl.current.on('error', ({ isValid, error }: OnErrorParams) => {
      setErrorMonthMessages(isValid ? '' : error || 'Unknown error');
    });
    expiryYearEl.current.on('error', ({ isValid, error }: OnErrorParams) => {
      setErrorYearMessages(isValid ? '' : error || 'Unknown error');
    });
  }, [elements]);

  const className = `${styles.inputHelperText} ${errorMonthMessages || errorYearMessage ? styles.error : ''}`;

  return (
    <div>
      <div className={styles.inputWrapper} style={{ border: borderStyle }}>
        <div id="cardExpiryMonth" className={styles.expirationMonth} />
        <span className={styles.divider}>/</span>
        <div id="cardExpiryYear" className={styles.expirationYear} />
      </div>
      <p className={className}>
        {errorMonthMessages} {errorYearMessage}
      </p>
    </div>
  );
};
