import MoneyHashHeadless, { IntentDetails } from '@moneyhash/js-sdk/headless';
import * as Sentry from '@sentry/react';
import { useCallback } from 'react';

export interface Params {
  moneyHashInstance: MoneyHashHeadless<'payment'>;
  intentId: string;
  currency: string;
  countryCode: string;
  amount: number;
  onResult: (res: {
    success: boolean;
    message?: string;
    validationErrors?: Record<string, string>;
  }) => void;
}

export const usePaymentHandlers = (params: Params) => {
  const { intentId, amount, currency, moneyHashInstance, countryCode, onResult } = params;

  if (moneyHashInstance === null) {
    throw new Error('moneyHashInstance is undefined');
  }

  if (!intentId) {
    throw new Error('Intent should be defined');
  }

  if (!currency) {
    throw new Error('Currency should be defined');
  }

  if (!countryCode) {
    throw new Error('Country code should be defined');
  }

  if (!amount) {
    throw new Error('Amount should be defined');
  }

  const payWithApplePay = useCallback(async () => {
    try {
      await moneyHashInstance.payWithApplePay({
        intentId,
        countryCode,
        currency,
        amount,
        onError: () => {
          Sentry.captureMessage('Apple Pay payment failed');
          onResult({ success: false });
        },
        onComplete: () => {
          onResult({ success: true });
        },
        onCancel: () => {
          Sentry.captureMessage('Apple Pay payment canceled');
          onResult({ success: false });
        },
      });
    } catch (error) {
      Sentry.captureMessage(`Apple Pay payment failed ${JSON.stringify(error)}`);
      if (error instanceof Error) {
        onResult({ success: false, message: error?.message });
      } else {
        onResult({ success: false });
      }
    }
  }, [onResult, intentId, currency, countryCode, amount, moneyHashInstance]);

  const handleUrlToRender = useCallback(
    (response: IntentDetails<'payment'>) => {
      if (
        response?.stateDetails &&
        'url' in response.stateDetails &&
        typeof response.stateDetails.url === 'string'
      ) {
        const { url, renderStrategy } = response.stateDetails;
        moneyHashInstance.renderUrl({
          url,
          intentId,
          renderStrategy,
        });
      } else {
        onResult({ success: false, message: 'URL is not provided' });
      }
    },
    [onResult, intentId, moneyHashInstance.renderUrl]
  );

  const handleTransactionStatus = useCallback(
    (response: IntentDetails<'payment'>) => {
      if (response.transaction.status === 'purchase.successful') {
        onResult({ success: true });
      } else {
        Sentry.captureMessage(
          `Card Payment handleTransactionStatus failed ${JSON.stringify(response)}`
        );
        onResult({
          success: false,
          message: response.transaction.responseMessage || 'Transaction failed',
        });
      }
    },
    [onResult]
  );

  const renderUrl = useCallback(
    (response: IntentDetails<'payment'>) => {
      if (response.state === 'URL_TO_RENDER') {
        handleUrlToRender(response);
      } else {
        handleTransactionStatus(response);
      }
    },
    [handleUrlToRender, handleTransactionStatus]
  );

  const payWithCard = useCallback(
    async (accessToken: string, billingData: Record<string, string>) => {
      if (!intentId) {
        console.error('intentId is null');
        return;
      }

      await moneyHashInstance
        .submitForm({
          intentId,
          accessToken,
          paymentMethod: 'CARD',
          billingData,
        })
        .then(renderUrl)
        .catch(err => {
          Sentry.captureMessage(`Card Payment submitForm failed ${JSON.stringify(err)}`);
          onResult({ success: false, validationErrors: err });
        });
    },
    [intentId, moneyHashInstance, onResult, renderUrl]
  );

  const payWithGooglePay = useCallback(() => {
    moneyHashInstance
      .payWithGooglePay({
        intentId,
        onCancel: () => {
          onResult({ success: false, message: 'Payment is canceled' });
        },
      })
      .then(res => {
        if (res && res.transaction.status === 'purchase.successful') {
          onResult({ success: true });
        } else {
          Sentry.captureMessage(`Google Pay payment failed ${JSON.stringify(res)}`);
          onResult({ success: false, message: res?.transaction?.responseMessage || '' });
        }
      })
      .catch(err => {
        Sentry.captureMessage(`Google Pay payment failed ${JSON.stringify(err)}`);
        onResult({ success: false });
      });
  }, [onResult, moneyHashInstance, intentId]);

  return {
    handleUrlToRender,
    payWithCard,
    payWithApplePay,
    payWithGooglePay,
  };
};
