import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { MoneyHashInitializer } from './MoneyHashInitializer';
import { useCreateIntent } from './useCreateIntent';

interface Props {
  webhookUrl?: string;
  successRedirectUrl: string;
  failedRedirectUrl: string;
  amount: number;
  tipAmount: number;
  accentColor?: string;
  currency: string;
  countryCode: string;
  onResult: (res: { success: boolean; message?: string }) => void;
}

export const MoneyHashPayments = (props: Props) => {
  const [intentId, setIntentId] = useState<string | null>(null);
  const { mutate: createIntent, isPending } = useCreateIntent();
  const {
    amount,
    currency,
    tipAmount,
    webhookUrl,
    onResult,
    successRedirectUrl,
    failedRedirectUrl,
  } = props;

  useEffect(() => {
    if (!intentId && !isPending) {
      const threedsQueryParam = new URLSearchParams(window.location.search).get('threeds');

      createIntent(
        {
          amount,
          currency,
          webhookUrl: webhookUrl || '',
          successful_redirect_url: successRedirectUrl,
          failed_redirect_url: failedRedirectUrl,
          extraCharges: {
            tipAmount: tipAmount || 0,
          },
          threeds:
            threedsQueryParam === 'true' ? true : threedsQueryParam === 'false' ? false : null,
        },
        {
          onSuccess: data => {
            setIntentId(data.paymentId);
          },
          onError: err => {
            Sentry.captureMessage(`Failed to create payment intent ${JSON.stringify(err)}`);
            onResult({ success: false, message: 'Failed to create payment intent' });
          },
        }
      );
    }
  }, [
    amount,
    intentId,
    isPending,
    createIntent,
    currency,
    tipAmount,
    webhookUrl,
    onResult,
    successRedirectUrl,
    failedRedirectUrl,
  ]);

  if (intentId && !isPending) {
    return <MoneyHashInitializer {...props} intentId={intentId} />;
  }
  return <span>Loading...</span>;
};
